import { useRef, useState } from 'react'
import emailjs from '@emailjs/browser';

export const Contact = (props) => {
  const form = useRef();
  const [sentMail, setSentMail] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    emailjs
      .sendForm(
        'service_kq4aix8', 'template_oeho3tf', form.current, 'pBXMWlfgYIJHaBBj6'
      )
      .then(
        (result) => {
          form.current.reset();
          setSentMail(true)
        },
        (error) => {
          alert("An error occured while sending the email. We apologise for the inconvenience caused. Alternatively, you may whatsapp us my clicking on the whatsapp icon below, or whatsapping +65 9788 6749.")
          form.current.reset();
          setSentMail(true)
        }
      )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Get In Touch with us!</h2>
                <p>
                  Please fill out the form below to reach out to us and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name='sentMessage' ref={form} validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='from_name'
                        className='form-control'
                        placeholder='Name'
                        required
                        // onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='reply_to'
                        className='form-control'
                        placeholder='Email'
                        required
                        // onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    required
                    // onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' value="Send" className='btn btn-custom btn-lg'>
                  Send Message
                </button>
                <p>
                  <strong>{sentMail && "Mail sent successfully!"}</strong>
                </p>
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Address
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Mobile
                </span>{' '}
                {props.data ? props.data.mobile : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Office
                </span>{' '}
                {props.data ? props.data.office : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope'></i> Email
                </span>{' '}
                {props.data ? <a href={"mailto:"+props.data.email}>{props.data.email}</a> : 'loading'}
              </p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.whatsapp : '/'}>
                      <p><strong>Whatsapp us at</strong></p><i className='fa fa-brands fa-whatsapp'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
